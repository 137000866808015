<template>
  <v-card>
    <v-card-title>
      Общая стоимость
    </v-card-title>

    <v-card-text v-if="hasDelivery" class="d-flex justify-space-between">
      <span class="caption">Доставка</span> <span>{{ deliveryWithoutDiscount }} &#8381;</span>
    </v-card-text>
    <v-card-text v-if="hasDelivery && hasDeliveryDiscount" class="d-flex justify-space-between">
      <span class="caption">Скидка по доставке</span> <span class="red--text text--lighten-1">- {{ parseFloat(order.deliveryDiscount).toFixed(2) }} &#8381;</span>
    </v-card-text>
    <v-card-text class="d-flex justify-space-between">
      <span class="caption">Товары ({{ goodsCount }})</span> <span>{{ linesAmountWithoutDiscount }} &#8381;</span>
    </v-card-text>

    <v-card-text v-if="hasLinesAmountDiscount" class="d-flex justify-space-between">
      <span class="caption">Скидка на товары</span> <span class="red--text text--lighten-1">- {{ linesAmountDiscount }} &#8381;</span>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-text class="d-flex justify-space-between">
      <span class="caption">ИТОГО</span> <span class="headline">= {{ order.totalAmount }} &#8381;</span>
    </v-card-text>

  </v-card>
</template>
<script>
export default {
  name: 'OrderBriefCard',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasDelivery() {
      return this.order?.deliveryType === 'Delivery'
    },
    hasDeliveryDiscount() {
      return parseFloat(this.order.deliveryDiscount) > 0
    },
    hasLinesAmountDiscount() {
      return this.calculateLinesAmountDiscount() > 0
    },
    goodsCount() {
      return this.order?.lines?.map((l) => l.quantity)
        .reduce((acc,currentValue) => acc + parseInt(currentValue), 0)
    },
    linesAmountDiscount: function(state) {
      return this.calculateLinesAmountDiscount()
    },
    deliveryWithoutDiscount() {
      return parseFloat(this.order.deliveryPrice).toFixed(2)
    },
    linesAmountWithoutDiscount() {
      const amount = this.order?.lines?.map((l) => l.amount)
        .reduce((acc,currentValue) => acc + parseFloat(currentValue), 0)

      return parseFloat(amount).toFixed(2)
    }
  },
  methods: {
    calculateLinesAmountDiscount() {
      return this.order?.lines?.map((l) => l.amountDiscount)
        .reduce((acc,currentValue) => acc + parseFloat(currentValue), 0)
    }
  }
}
</script>
